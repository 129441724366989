<pxc-image-overlay
  [caption]="file?.name"
  [showDelete]="showDelete"
  (delete)="delete.emit()"
>
  @if (src$ | async; as src) {
    @switch (fileType) {
      @case (MediaType.IMAGE) {
        @if ((error$ | async) === null) {
          <img
            class="h-full w-full object-cover"
            [src]="src"
            (error)="error$.next($event)"
            data-testid="image"
          />
        } @else {
          <pxc-image-broken data-testid="error"></pxc-image-broken>
        }
      }
      @case (MediaType.VIDEO) {
        <pxc-video-info
          class="absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent via-primary/40 via-60% to-primary/60 text-white"
          [duration]="duration$ | async"
        ></pxc-video-info>
        @if ((error$ | async) === null) {
          <video
            class="h-full w-full object-cover"
            [src]="src"
            (error)="error$.next($event)"
            (loadedmetadata)="duration$.next($event.target.duration)"
            data-testid="video"
          ></video>
        } @else {
          <pxc-image-broken
            icon="@tui.video-off"
            data-testid="error"
          ></pxc-image-broken>
        }
      }
      @default {
        <pxc-image-broken data-testid="error"></pxc-image-broken>
      }
    }
  } @else {
    <pxc-image-loading data-testid="loading"></pxc-image-loading>
  }
</pxc-image-overlay>
