@switch (mediaType) {
  @case (MediaType.IMAGE) {
    @if (mediaRetryStrategy.hasReachedMaxRetries || !src) {
      <!-- Media can't be loaded, even after retries -->
      <ng-container *ngTemplateOutlet="mediaBroken"></ng-container>
    } @else {
      <img
        class="aspect-square w-[inherit] rounded"
        [hidden]="isMediaLoading"
        (load)="mediaLoaded()"
        (error)="loadingError()"
        [src]="src"
        data-testid="image"
      />
      @if (isMediaLoading) {
        <!-- Media is loading -->
        <ng-container *ngTemplateOutlet="mediaLoading"></ng-container>
      }
    }
  }

  @case (MediaType.VIDEO) {
    <div class="relative aspect-square h-[inherit] w-[inherit] overflow-hidden rounded">
      @if (!!src) {
        <pxc-video-info
          class="absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent via-primary/40 via-60% to-primary/60 text-white"
          [duration]="media.duration"
        ></pxc-video-info>

        <!-- Thumbnail is present, video is ready -->
        <img
          class="aspect-square w-[inherit] rounded object-cover"
          [hidden]="isMediaLoading"
          (load)="mediaLoaded()"
          (error)="loadingError()"
          [src]="src"
          data-testid="image"
        />
        @if (isMediaLoading) {
          <!-- Media is loading -->
          <ng-container *ngTemplateOutlet="mediaLoading"></ng-container>
        }
      } @else {
        <!-- No thumbnail, video is processing -->
        <pxc-image-broken
          data-testid="video-processing"
          icon="@tui.hourglass"
        ></pxc-image-broken>
      }
    </div>
  }

  @default {
    <ng-container *ngTemplateOutlet="mediaBroken"></ng-container>
  }
}

<ng-template #mediaBroken>
  <pxc-image-broken
    data-testid="image-broken"
    class="aspect-square h-[inherit] w-[inherit] rounded"
  ></pxc-image-broken>
</ng-template>

<ng-template #mediaLoading>
  <pxc-image-loading
    data-testid="spinner"
    class="aspect-square h-[inherit] w-[inherit] rounded"
  ></pxc-image-loading>
</ng-template>
