@if (error$ | async; as error) {
  <p class="text-red-600">{{ error }}</p>
}

@if (info$ | async; as info) {
  <p class="text-neutral-1">{{ info }}</p>
}

@if (streamUrl$ | async; as streamUrl) {
  <vg-player
    class="justify-center"
    (onPlayerReady)="onPlayerReady($event)"
  >
    <video
      #videoPlayer
      #vgHls="vgHls"
      [vgMedia]="videoPlayer"
      [vgHls]="streamUrl"
      controls
      [autoplay]="!hidden"
      crossorigin
      (play)="onPlay()"
      (pause)="onPause()"
      data-testid="player"
    ></video>
  </vg-player>
} @else {
  <pxc-loader data-testid="loader"></pxc-loader>
}
