import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { GalleryConfig } from 'src/app/shared/models/gallery-config';
import { GalleryInput } from 'src/app/shared/models/gallery-input';
import { GalleryItem } from 'src/app/shared/models/gallery-item';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { Widget } from 'src/app/shared/models/widget';
import { defaultSliderConfig } from './gallery.configs';

@UntilDestroy()
export class GalleryModel {

  items$: Observable<GalleryItem[]>;

  config: GalleryConfig = defaultSliderConfig;

  displayIdx: number;
  actions: MenuAction[];
  fixedWidget: Widget;
  imageWidgets: Widget[];

  onImageLoadingError: (idx: number) => Promise<void> | void;

  constructor(
    {
      items$,
      openIdx,
      actions,
      onImageLoadingError,
      config,
      fixedWidget,
      imageWidgets,
    }: Required<Omit<GalleryInput, 'items'>>,
  ) {
    this.displayIdx = openIdx;
    this.actions = actions;
    this.fixedWidget = fixedWidget;
    this.imageWidgets = imageWidgets;
    this.items$ = items$;
    this.config = config;
    this.onImageLoadingError = onImageLoadingError;

    // Forced config property
    this.items$.pipe(untilDestroyed(this)).subscribe((items) => {
      this.config.nav = items.length > 1;
    });
  }

  setDisplayIdx(idx: number): void {
    this.displayIdx = idx;
  }

  setConfig(galleryConfig: GalleryConfig): void {
    this.config = {
      ...this.config,
      ...galleryConfig,
    };
  }

  navNext(): void {
    this.items$.pipe(first()).subscribe((items) => {
      this.displayIdx = this.getNextIdx(this.displayIdx, items.length);
    });
  }

  navPrev(): void {
    this.items$.pipe(first()).subscribe((items) => {
      this.displayIdx = this.getPrevIdx(this.displayIdx, items.length);
    });
  }

  navTo(idx: number): void {
    this.items$.pipe(first()).subscribe((items) => {
      this.displayIdx = Math.max(Math.min(idx, items.length - 1), 0);
    });
  }

  navLast(): void {
    this.items$.pipe(first()).subscribe((items) => {
      this.displayIdx = items.length - 1;
    });
  }

  getNextIdx(idx: number, length: number): number {
    return idx === length - 1
      ? 0
      : this.displayIdx + 1;
  }

  getPrevIdx(idx: number, length: number): number {
    return idx === 0
      ? length - 1
      : this.displayIdx - 1;
  }

}
