import { CropConfig, Media, mimeTypesByExtension, PictureAnnotations, Shape } from '@pixacare/pxc-ts-core';
import { Command } from 'src/app/shared/models/helpers/command';
import { StudioCanvas } from '../canvas/studio-canvas';
import { StudioShape } from '../config/studio-enums';
import { EventBus } from '../event-bus/event-bus';
import { EventTopic } from '../event-bus/event-bus.interfaces';
import { StudioService } from '../studio/studio.service';

export class SaveCommand implements Command {

  receiver: StudioCanvas;
  imageData: Media;
  cropConfig: CropConfig;
  pixelRatio: number;

  eventBus: EventBus;

  constructor(
    receiver: StudioCanvas,
    imageData: Media,
    cropConfig: CropConfig,
    pixelRatio: number,
    eventBus: EventBus,
    private studioService: StudioService,
  ) {
    this.receiver = receiver;
    this.imageData = imageData;
    this.cropConfig = cropConfig;
    this.pixelRatio = pixelRatio;
    this.eventBus = eventBus;
  }

  execute(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.receiver.deselectShapes();
      this.receiver.destroyCropSelection();

      const pictureAnnotations = this.getPictureAnnotations();

      const base64Canvas = this.receiver.stage.toDataURL({
        mimeType: mimeTypesByExtension.jpeg,
        pixelRatio: this.pixelRatio,
      });

      this.studioService.savePicture(this.imageData, pictureAnnotations, base64Canvas, true)
        .then(() => {
          this.eventBus.publish(EventTopic.SAVE);
          resolve();
        })
        .catch(() => reject());
    });
  }

  getPictureAnnotations(): PictureAnnotations {
    const stageWidth = this.receiver.stage.width() / this.receiver.layer.scaleX();
    const stageHeight = this.receiver.stage.height() / this.receiver.layer.scaleX();
    return {
      transformations: {
        rotation: this.receiver.stage.rotation() % 360,
        cropConfig: !this.cropConfig || !this.cropConfig.width || !this.cropConfig.height
          ? {
            x: 0,
            y: 0,
            width: this.receiver.isVertical() ? stageHeight : stageWidth,
            height: this.receiver.isVertical() ? stageWidth : stageHeight,
          }
          : this.cropConfig,
      },
      shapes: this.receiver.layer.find(`.${StudioShape.SHAPE_NAME}`).map((shape): Shape => ({
        type: shape.className,
        x: shape.x(),
        y: shape.y(),
        width: shape.width(),
        height: shape.height(),
        radiusX: shape.getAttr('radiusX'),
        radiusY: shape.getAttr('radiusY'),
        offsetX: shape.offsetX(),
        offsetY: shape.offsetY(),
        scaleX: shape.scaleX(),
        scaleY: shape.scaleY(),
        rotation: shape.rotation(),
        stroke: shape.attrs.stroke,
        strokeWidth: shape.attrs.strokeWidth,
        points: shape.attrs.points ? shape.attrs.points : null,
        lineCap: shape.attrs.lineCap,
        lineJoin: shape.attrs.lineJoin,
      })),
    };
  }

}
