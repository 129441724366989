<div class="my-5 flex flex-col items-center gap-6 px-4">
  <div class="flex items-center justify-center gap-3">
    <tui-icon
      [icon]="icon || defaultStatusInformations[state].icon"
      class="h-8 w-8"
      data-testid="icon"
      [ngClass]="[defaultStatusInformations[state].iconColor, defaultStatusInformations[state].iconStyle ?? '']"
    ></tui-icon>
    @if (showTitle) {
      <h5
        tuiTitle="m"
        [ngClass]="defaultStatusInformations[state].iconColor"
      >
        {{ title || defaultStatusInformations[state].title }}
      </h5>
    }
  </div>
  <p class="max-w-prose text-balance text-center text-lg text-tertiary">
    {{ description || defaultStatusInformations[state].description }}
  </p>
  <ng-content></ng-content>
</div>
