<!-- Drawer Drop List -->
@if (drawer$ | async; as drawer) {
  <div
    class="droplist-ctrl-container absolute hidden select-none overflow-hidden lg:block"
    [ngClass]="[drawer.state, drawer.medias.length > 0 ? 'full' : 'empty']"
  >
    <div
      id="drawer-list"
      class="droplist-ctrl hidden flex-col !px-3 !pb-0 !pt-1 lg:flex"
      [ngClass]="[drawer.state, drawer.medias.length > 0 ? 'full' : 'empty']"
      [class.dragging]="drawer.isUserDragging"
      cdkDropList
      [cdkDropListData]="drawer.medias"
      [cdkDropListEnterPredicate]="alreadyPresentPredicate"
      (cdkDropListDropped)="drop($event)"
      (cdkDropListEntered)="isUserSorting = true"
      (cdkDropListExited)="isUserSorting = false"
      data-testid="droplist"
    >
      <!-- Image List -->
      @for (media of drawer.medias; track media; let idx = $index) {
        <div
          class="thumbnail group relative mx-0 my-1 cursor-grab first-of-type:mt-0"
          cdkDrag
        >
          @if (!drawer.isUserDragging) {
            <button
              type="button"
              tuiButtonClose
              tuiIconButton
              class="!absolute !right-1 !top-1 opacity-0 transition-opacity group-hover:opacity-100"
              (click)="removeThumbnail(idx)"
              [attr.data-testid]="'remove-media-' + idx"
            ></button>
          }
          <pxc-sequence-media
            [media]="media"
            class="w-full"
            data-testid="media"
          ></pxc-sequence-media>
          <div
            *cdkDragPlaceholder
            class="border-border-pressed aspect-square w-full rounded-xl border-2 border-dashed bg-neutral-1"
          ></div>
        </div>
      }
    </div>
  </div>
  <!-- Drawer  -->
  <div
    class="drawer-container absolute hidden flex-col items-center rounded-xl border border-border-normal bg-base p-4 lg:flex"
    [ngClass]="drawer.state"
    [class.pb-2]="drawer.state !== DrawerState.TINY"
    [class.dragging]="drawer.isUserDragging"
  >
    <!-- Handle -->
    <pxc-handle
      class="drawer-handle absolute z-[1001] -translate-y-full rounded-full shadow"
      [class.dragging]="!drawer.isUserDragging && [DrawerState.TINY, DrawerState.COMPACT].includes(drawer.state)"
      (handleClicked)="updateDrawerState()"
      data-testid="handle"
    ></pxc-handle>
    <!-- Informations -->
    <div class="relative flex w-full flex-1 flex-col items-center gap-2">
      <div class="flex items-center justify-center gap-2">
        @if (drawer.medias.length > 0) {
          <p
            class="rounded-full bg-neutral-1-pressed px-2 py-px"
            data-testid="media-count"
          >
            {{ drawer.medias.length > 99 ? '99+' : drawer.medias.length }}
          </p>
        }
        <tui-icon
          class="z-[1001]"
          tuiTooltip="Le tiroir de comparaison vous permet de glisser vos photos puis de les comparer."
          tuiHintDirection="left"
          data-testid="tooltip-icon"
        ></tui-icon>
      </div>
      @if (
        !isUserSorting && (drawer.state === DrawerState.OPEN || drawer.isUserDragging) && drawer.medias.length <= 0
      ) {
        <div
          class="empty-drawer-arrow my-auto flex w-full flex-col items-center justify-center gap-2 text-center"
          data-testid="expanded-title"
        >
          <tui-icon icon="@tui.corner-right-down"></tui-icon>
          <p class="text-sm">Glisser ici pour comparer.</p>
        </div>
      }
      @if (drawer.state === DrawerState.TINY && !drawer.isUserDragging) {
        <p
          class="text-center text-xs text-secondary"
          data-testid="tiny-title"
        >
          Comparez vos photos
        </p>
      }
    </div>
    <!-- Bottom Buttons -->
    @if (drawer.medias.length > 0) {
      <div class="my-1 flex w-full flex-col items-stretch gap-1">
        <button
          tuiButton
          type="button"
          appearance="secondary"
          size="s"
          (click)="loadImageInGallery()"
          data-testid="compare-button"
        >
          Comparer
        </button>
        <button
          tuiButton
          type="button"
          appearance="destructive"
          size="s"
          (click)="clearItems()"
          data-testid="clear-button"
        >
          Vider
        </button>
      </div>
    }
  </div>
}

