<tui-items-with-more
  [required]="displayedSequencePreviews"
  [class.justify-center]="centered"
>
  @for (sequence of sequences; track sequence) {
    <div
      *tuiItem
      class="group relative h-32 w-32"
      [attr.data-testid]="'sequence-' + sequence.sequenceInstance.id"
    >
      @for (media of sequence.medias; track media; let idx = $index) {
        @if (idx < 3) {
          <pxc-sequence-media
            [media]="media"
            class="absolute h-24 w-24 cursor-zoom-in rounded-xl border-t border-base/20 shadow-sm transition-all duration-300 ease-out hover:!shadow-xl hover:brightness-110"
            [ngClass]="{
              'left-0 top-0 z-30 brightness-100': idx === 0,
              'left-3 top-3 z-20 scale-95 brightness-95 group-hover:left-4 group-hover:top-4': idx === 1,
              'left-6 top-6 z-10 scale-90 brightness-90 group-hover:left-8 group-hover:top-8': idx === 2,
            }"
            (click)="mediaClick(idx, sequence)"
            [attr.data-testid]="'sequence-media-' + sequence.sequenceInstance.id"
          ></pxc-sequence-media>
        }
      }
    </div>
  }
  <ng-template
    tuiMore
    let-index
  >
    <p
      *tuiLet="getRemaining(index) as remaining"
      class="text-center"
    >
      <span class="text-xl font-bold">+{{ remaining }}</span>
      <br />
      {{ remaining | i18nPlural: sequencesPluralMapping }}
    </p>
  </ng-template>
</tui-items-with-more>
