import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { VideoInfoComponent } from '@modules/shared/video-info/video-info.component';
import { Store } from '@ngrx/store';
import { Media, MediaType } from '@pixacare/pxc-ts-core';
import { sequencesActions } from 'src/app/shared/store/sequences/sequences.actions';
import { ImageBrokenComponent } from '../../shared/image-broken/image-broken.component';
import { ImageLoadingComponent } from '../../shared/image-loading/image-loading.component';
import { MediaRetryStrategy } from './media-retry-strategy/media-retry-strategy';

@Component({
  selector: 'pxc-sequence-media',
  standalone: true,
  imports: [
    CommonModule,
    ImageBrokenComponent,
    ImageLoadingComponent,
    VideoInfoComponent,
  ],
  templateUrl: './sequence-media.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceMediaComponent implements OnInit {

  @Input() media: Media;

  isMediaLoading = true;

  MediaType = MediaType;
  mediaRetryStrategy: MediaRetryStrategy;

  constructor(private readonly store: Store) {
    this.mediaRetryStrategy = new MediaRetryStrategy(() => {
      this.store.dispatch(sequencesActions.updateSequenceMedia({
        clientCode: this.media.clientCode,
        sequenceId: this.media.sequenceId,
        mediaId: this.media.id,
      }));
    });
  }

  get mediaType(): MediaType {
    return this.media.type;
  }

  get src(): string {
    return this.media.thumbnailUri;
  }

  ngOnInit(): void {
    if (this.mediaType === MediaType.IMAGE) {
      if (!this.src) {
        this.mediaRetryStrategy.startRetry();
      }
    }
  }

  mediaLoaded(): void {
    this.isMediaLoading = false;
    this.mediaRetryStrategy.stopRetry();
  }

  loadingError(): void {
    this.mediaRetryStrategy.startRetry();
  }

}
