<pxc-carousel-navigation
  [max]="medias.length"
  [(index)]="currentThumbnailIndex"
  hideControlsOnMobile="true"
>
  <tui-carousel
    #carousel
    class="carousel w-full"
    [itemsCount]="3"
    [(index)]="currentThumbnailIndex"
  >
    @for (media of medias; track trackByMediaId(media); let idx = $index) {
      <div
        *tuiItem
        cdkDropList
        [cdkDropListConnectedTo]="['drawer-list']"
      >
        <div
          (cdkDragStarted)="dragStart()"
          (cdkDragEnded)="dragEnd()"
          [cdkDragData]="getMedia(media)"
          cdkDrag
          [attr.data-testid]="'drag-' + idx"
        >
          <ng-container [ngTemplateOutlet]="sequenceMedia"></ng-container>
          <ng-container *cdkDragPlaceholder>
            <div>
              <ng-container [ngTemplateOutlet]="sequenceMedia"></ng-container>
            </div>
          </ng-container>
          <ng-template #sequenceMedia>
            <pxc-sequence-media
              class="flex h-full w-full cursor-pointer"
              (click)="emitMediaClicked(idx)"
              [media]="getMedia(media)"
              data-testid="thumbnail"
            ></pxc-sequence-media>
          </ng-template>
        </div>
      </div>
    }
  </tui-carousel>
</pxc-carousel-navigation>
