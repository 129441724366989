import { mimeTypesByExtension } from '@pixacare/pxc-ts-core';
import { Command } from 'src/app/shared/models/helpers/command';
import { downloadSource } from 'src/app/shared/utils/utils';
import { StudioCanvas } from '../canvas/studio-canvas';

export class DownloadCommand implements Command {

  receiver: StudioCanvas;
  ratio: number;
  constructor(receiver: StudioCanvas, ratio = 1) {
    this.receiver = receiver;
    this.ratio = ratio;
  }

  execute(): void {
    downloadSource(this.receiver.stage.toDataURL({
      mimeType: mimeTypesByExtension.jpeg,
      pixelRatio: this.ratio,
    }), 'studio.jpeg');
  }

}
