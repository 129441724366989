import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MediaType } from '@pixacare/pxc-ts-core';
import { Subject } from 'rxjs';
import { ImageBrokenComponent } from '../image-broken/image-broken.component';
import { ImageLoadingComponent } from '../image-loading/image-loading.component';
import { ImageOverlayComponent } from '../image-overlay/image-overlay.component';
import { SharedModule } from '../shared.module';
import { VideoInfoComponent } from '../video-info/video-info.component';

@UntilDestroy()
@Component({
  selector: 'pxc-file-media-preview',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ImageOverlayComponent,
    ImageBrokenComponent,
    ImageLoadingComponent,
    VideoInfoComponent,
  ],
  templateUrl: './file-media-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileMediaPreviewComponent implements OnInit {

  @Input() file: File;

  @Input() showDelete = true;

  @Output() delete = new EventEmitter<void>();

  duration$ = new Subject<number>();
  src$ = new Subject<string>();
  error$ = new Subject<string>();

  MediaType = MediaType;
  
  get fileType(): MediaType {
    if (this.file.type.startsWith('image/')) {
      return MediaType.IMAGE;
    } else if (this.file.type.startsWith('video/')) {
      return MediaType.VIDEO;
    }  
    return null;
  }

  ngOnInit(): void {

    this.error$.pipe(untilDestroyed(this)).subscribe((error) => {
      console.error('Error loading media', error);
    });

    if (!this.file) {
      this.error$.next('Missing file');
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const result = e.target?.result as string;
      this.src$.next(result);
    };

    reader.readAsDataURL(this.file);
  }

}
