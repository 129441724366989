import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DurationPipe } from '@shared/pipes/duration.pipe';
import { TuiIcon } from '@taiga-ui/core';

@Component({
  selector: 'pxc-video-info',
  standalone: true,
  imports: [CommonModule, TuiIcon, DurationPipe],
  templateUrl: './video-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoInfoComponent {

  @Input() duration: number;

}
