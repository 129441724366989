<tui-scrollbar class="w-screen">
  <div class="mx-auto flex w-max snap-x justify-center gap-2 px-2">
    @for (item of items; track trackByGalleryItem(item); let idx = $index) {
      <pxc-gallery-thumbnail
        [item]="item"
        [active]="idx === activeIdx"
        (thumbnailClicked)="thumbnailClicked.emit(idx)"
        [attr.data-testid]="'thumbnail-' + idx"
      ></pxc-gallery-thumbnail>
    }
  </div>
</tui-scrollbar>
