<div
  class="grid grid-cols-5 gap-4"
  *tuiLet="files?.length || rejectedFiles?.length || medias?.length as hasFiles"
>
  @for (media of medias; track media; let idx = $index) {
    <pxc-image-overlay
      [showDelete]="showDelete && !disabled"
      (delete)="removeMedia(media)"
      [attr.data-testid]="'media-' + media.id"
    >
      <pxc-sequence-media
        [media]="media"
        (click)="mediaClickedIdx.emit(idx)"
        class="cursor-zoom-in"
        [attr.data-testid]="'seq-media-' + media.id"
      ></pxc-sequence-media>
      <img
        [src]="media.thumbnailUri"
        class="aspect-square w-full rounded-xl"
      />
    </pxc-image-overlay>
  }

  @for (file of files; track file) {
    <pxc-file-media-preview
      [file]="file"
      class="w-full"
      [showDelete]="showDelete && !disabled"
      (delete)="removeFile(file)"
      [attr.data-testid]="'file-' + file.name"
    ></pxc-file-media-preview>
  }

  @for (file of rejectedFiles; track file) {
    <pxc-image-overlay
      (delete)="removeRejected(file)"
      [showDelete]="showDelete && !disabled"
    >
      <div
        class="flex aspect-square w-full select-none flex-col items-center justify-center gap-2 rounded-xl bg-status-negative-pale p-2"
      >
        <tui-icon
          icon="@tui.image-off"
          class="text-status-negative"
        ></tui-icon>
        <p class="line-clamp-2 max-w-full break-all text-center text-sm text-secondary">
          {{ file.name }}
        </p>
        <p class="text-center text-xs text-secondary">Ce fichier n'est pas pris en charge</p>
      </div>
    </pxc-image-overlay>
  }

  <label
    tuiInputFiles
    class="aspect-square"
    [ngClass]="{
      'col-span-5': !hasFiles,
      'aspect-square w-full': hasFiles,
    }"
  >
    <input
      tuiInputFiles
      [accept]="accept"
      [multiple]="true"
      (ngModelChange)="onFileChange($event)"
      [ngModel]="files"
      [disabled]="disabled"
      (reject)="onReject($event)"
      data-testid="image-input"
      ngDefaultControl
    />

    <ng-template let-dragged>
      <div class="flex flex-col items-center gap-3 py-6 text-sm">
        @if (dragged) {
          <tui-icon
            class="text-pixablue-1"
            icon="@tui.smile"
          ></tui-icon>
          Déposez l'image ici !
        } @else {
          <tui-icon
            class="text-secondary"
            icon="@tui.plus"
          ></tui-icon>
          <span class="text-secondary">{{ label }}</span>
        }
      </div>
    </ng-template>
  </label>
</div>

