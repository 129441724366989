<article
  class="h-24 w-24 cursor-pointer snap-center overflow-hidden rounded transition"
  [ngClass]="{ 'brightness-50 hover:brightness-90': !active }"
  (click)="onThumbnailClicked()"
  #thumbnail
>
  @if (item.media) {
    <pxc-sequence-media [media]="item.media"></pxc-sequence-media>
  } @else if (item.innerHtml) {
    <div class="flex h-full w-full items-center justify-center bg-base-alt">
      <tui-icon icon="@tui.file"></tui-icon>
    </div>
  }
</article>
