<div class="flex items-center gap-1 p-2 text-sm">
  <tui-icon
    icon="@tui.video"
    class="fill-white text-base"
    data-testid="icon"
  />
  @if (duration) {
    <span data-testid="duration">{{ duration | duration }}</span>
  }
</div>
