import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SharedModule } from '@modules/shared/shared.module';
import { Media } from '@pixacare/pxc-ts-core';
import { MediaHttpService } from '@services/http/media.http.service';
import { VgApiService, VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
@Component({
  selector: 'pxc-video-player',
  standalone: true,
  imports: [
    CommonModule,
    VgCoreModule,
    VgStreamingModule,
    SharedModule,
  ],
  templateUrl: './video-player.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'w-full h-full flex items-center justify-center bg-black' },
})
export class VideoPlayerComponent implements OnInit, OnChanges {

  @Input() @HostBinding('class.hidden') hidden = false;
  @Input() media: Media;

  streamUrl$: Observable<string>;
  error$ = new BehaviorSubject<string>('');
  info$ = new BehaviorSubject<string>('');

  isPlaying = true;

  api: VgApiService;

  constructor(
    private readonly mediaService: MediaHttpService,
  ) { }

  ngOnInit(): void {

    if (!this.media.clientCode || !this.media.sequenceId || !this.media.filename) {
      this.error$.next('Impossible de charger la vidéo : données manquantes');
      return;
    }

    console.log('media', this.media);
    this.streamUrl$ = this.mediaService.getSequenceVideoMasterPlaylist(
      this.media.id,
      this.media.clientCode,
    ).pipe(
      map(({ uri }) => uri),
      catchError((error) => {
        if (error.status === 404) {
          this.info$.next(`La vidéo est en cours de traitement...`);
        } else {
          console.log('error2', error);
          this.error$.next(`Impossible de charger la vidéo : ${error}`);
        }
        return [];
      }),
    );

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.api || !changes.hidden || !this.isPlaying) {
      return;
    }

    if (this.hidden) {  
      this.api.pause();
    } else {
      this.api.play();
    }
  }

  onPlayerReady(api: VgApiService): void {
    this.api = api; 
  }
  
  onPlay(): void {
    this.isPlaying = true;
  }

  onPause(): void {
    // don't change playing state if video was paused because it was hidden
    if (!this.hidden) {
      this.isPlaying = false;
    } else {
      this.api.getDefaultMedia().currentTime = 0;
    }
  }

}
