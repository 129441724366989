import { Inject, Injectable, Injector } from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { Observable } from 'rxjs';
import { GalleryConfig } from 'src/app/shared/models/gallery-config';
import { GalleryInput } from '../../shared/models/gallery-input';
import { GalleryModel } from './gallery-model';
import { defaultSliderConfig } from './gallery.configs';
import { GalleryComponent } from './gallery/gallery.component';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {

  private galleryModel: GalleryModel;

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) { }

  get getDisplayIdx(): number {
    return this.galleryModel.displayIdx;
  }

  open({
    items$,
    items,
    openIdx = 0,
    actions,
    fixedWidget: imageWidget,
    onImageLoadingError,
    config = defaultSliderConfig,
    imageWidgets,
  }: GalleryInput): Observable<void> {

    this.galleryModel = new GalleryModel({
      items$: items$ ?? new Observable((observer) => observer.next(items)),
      openIdx,
      actions,
      fixedWidget: imageWidget,
      onImageLoadingError,
      config,
      imageWidgets,
    });

    return this.dialogService.open<void>(
      new PolymorpheusComponent(GalleryComponent, this.injector),
      {
        data: this.galleryModel,
        size: 'page',
        closeable: false,
        dismissible: false,
      },
    );

  }

  setConfig(config: GalleryConfig): void {
    this.galleryModel.setConfig(config);
  }

  navLastItem(): void {
    this.galleryModel.navLast();
  }

  navToItem(idx: number): void {
    this.galleryModel.navTo(idx);
  }

}
