import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
  standalone: true,
})
export class DurationPipe implements PipeTransform {

  transform(value: number): string {
    if (value === null || value === undefined || isNaN(value)) {
      return '--:--';
    }

    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = Math.floor(value % 60);

    const secondsString = seconds.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');
   
    if (hours > 0) {
      const hoursString = hours.toString().padStart(2, '0');
      return `${hoursString}:${minutesString}:${secondsString}`;
    } else {
      return `${minutesString}:${secondsString}`;
    }
  }

}
